import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { H5, Btn } from "../../AbstractElements";
import {
  Row,
  Col,
  CardBody,
  CardFooter,
  CardHeader,
  Form,
  FormGroup,
  Label,
  Input,
  Container,
  Card,
} from "reactstrap";
import { Cancel } from "../../Constant";
import SvgIcon from "../../Components/Common/Component/SvgIcon";
import { authOperations } from "../../state/ducks/auth";
import { validPassword } from "../validation.jsx";
import { Table } from "react-bootstrap";
import deleteIcon from '../../assets/images/datatable/delete.png';

class AddLanguage extends React.Component {
  constructor() {
    super();
    this.state = {
      //   isPasswordValid: true
      language_name: "", // Initialize with empty string
      language_code: "",
      language_id: "",
      languages: [],
    };
  }

  // Fetch the list of languages on component mount
  componentDidMount = async () => {
    try {
      const response = await this.props.getLanguages(); // API call to get languages
      if (response && response.payload) {
        this.setState({ languages: response.payload }); // Store the payload (list of languages) in state
      }
    } catch (err) {
      toast.error("Error fetching languages", { autoClose: 10000 });
    }
  };

  // Handle input change for both fields
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value, // Dynamically update state based on input field name
    });
  };

  //   handleChange = async (e) => {
  //     try {
  //       let tid = e.target.id;
  //       switch (tid) {
  //         case 'newPassword':
  //           const newPassword = e.target.value;
  //           const isPasswordValid = !newPassword || validPassword.test(newPassword);
  //           this.setState({ newPassword: newPassword, isPasswordValid });
  //           break;
  //         default:
  //           console.log("No Matching target found");
  //           break;
  //       }
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   };

  onInsert = async (event) => {
    event.preventDefault();
    try {
      let user = localStorage.getItem("user");
      if (user) {
        user = JSON.parse(user);
        this.setState({ user });
      }
      const payload = {
        // id: user.id,
        language_name: this.state.language_name,
        language_code: this.state.language_code,
      };
      const response = await this.props.addLanguage(payload);
      toast.success(response.message, { autoClose: 10000 });
      window.open("/additional-considerations/add-language/", "_self");
    } catch (err) {
      toast.error(err.response.data.message, { autoClose: 10000 });
      console.log(err);
    }
  };
  onDelete = async (e) => {
    // event.preventDefault();
    try {
    //   const payload = {
    //     id: this.state.language_id,
    //   };
    let languageId = e.target.getAttribute('data-test-id');
      const response = await this.props.deleteLanguage({id:languageId });
      console.log(response);
      toast.success(response.message, { autoClose: 1000 });
      window.open("/additional-considerations/add-language/", "_self");
    } catch (err) {
      toast.error(err.response.data.message, { autoClose: 1000 });
      console.log(err);
    }
  };

  render() {
    return (
      <Fragment>
        <ToastContainer />
        <div className="page-title">
          <Row>
            <Col xs="12">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/dashboard/">
                    <SvgIcon iconId="stroke-home" />
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link
                    style={{ color: "#000" }}
                    to="/additional-considerations/"
                  >
                    Additional Considerations
                  </Link>
                </li>
                <li className="breadcrumb-item active">Add Language</li>
              </ol>
            </Col>
          </Row>
        </div>
        <Container fluid={true}>
          <div className="edit-profile">
            <Row>
              <Col sm="12">
                <Card>
                  <CardHeader className="d-flex align-items-center justify-content-between">
                    <H5>Add Language</H5>
                  </CardHeader>
                  <Form onSubmit={this.onInsert}>
                    <CardBody>
                      <Row>
                        <Col md="6">
                          <FormGroup className="mb-3 mt-3">
                            <Label className="form-label">Language Name</Label>
                            <Input
                              className="form-control"
                              type="text"
                              placeholder="Enter Language Name"
                              name="language_name"
                              id="language_name"
                              required
                              value={this.state.language_name || ""}
                              onChange={this.handleChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup className="mb-3 mt-3">
                            <Label className="form-label">Language Code</Label>
                            <Input
                              type="text"
                              placeholder="Enter Language Code"
                              name="language_code"
                              id="language_code"
                              required
                              value={this.state.language_code || ""}
                              onChange={this.handleChange}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </CardBody>
                    <CardFooter className="text-center">
                      <Btn attrBtn={{ color: "primary", type: "submit" }}>
                        Add
                      </Btn>
                      &nbsp;&nbsp;&nbsp;
                      <Link to="/additional-considerations/">
                        <Btn attrBtn={{ color: "primary", type: "reset" }}>
                          {Cancel}
                        </Btn>
                      </Link>
                    </CardFooter>
                  </Form>
                  {/* List of languages with Delete button */}
                  <CardHeader className='d-flex align-items-center justify-content-between'>
                    <H5>Available Languages</H5>
                  </CardHeader>
                  <CardBody>
                    <Table  ref="tbl" striped hover responsive bordered id="data-table-zero">
                      <thead>
                        <tr>
                          <th>Sr. No</th>
                          <th>Language Name</th>
                          <th>Language Code</th>
                          <th>Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.languages.map(language => (
                          <tr key={language.id}>
                            <td>{language.id}</td>
                            <td>{language.language_name}</td>
                            <td>{language.language_code}</td>
                            <td>
                          <img src={deleteIcon} alt='delete' height={17} width={17} data-test-id={language.id} onClick={this.onDelete}/>

                              {/* <Btn attrBtn={{ color: "danger", onClick: () => this.onDelete(language.id) }}>Delete</Btn> */}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </CardBody>

                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </Fragment>
    );
  }
}
const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {
  addLanguage: authOperations.addLanguage,
  getLanguages: authOperations.getLanguages,
  deleteLanguage: authOperations.deleteLanguage,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddLanguage);
