import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Table } from "react-bootstrap";
import {
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Form,
  Row,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  Label,
} from "reactstrap";
import * as XLSX from "xlsx";
import { connect } from "react-redux";
import { H5, H4, Btn } from "../../AbstractElements";
import excelImg from "../../assets/images/datatable/excel.png";
import userCircle from "../../assets/images/datatable/user-circle.png";
import list from "../../assets/images/datatable/list.png";
import SvgIcon from "../../Components/Common/Component/SvgIcon";
import { formatDateAndTime } from "../../utils/helper";
import Pagination from "../Pagination";
import "../../assets/scss/pagination.css";
import receipt from "../../assets/images/datatable/receipt.png";
import icon from "../../assets/images/datatable/icon.png";
import { masterOperations } from "../../state/ducks/master";
import { orderOperations } from "../../state/ducks/order";
import { customerOperations } from "../../state/ducks/customer";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { printBookOrderOperations } from "../../state/ducks/printBookOrder";

class PrintingBookDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      BasicTab: "1",
      selectedUserData: {},
      selectedPackageData: [],
      orderBookDetail: [],
      additionalOrderDetails: [],
      orderItemsData: [],
      itemData: [],
      successItemsData: [],
      successOrderItem: [],
      pendingItemsData: [],
      processingItemsData: [],
      shippedItemsData: [],
      canceledItemsData: [],
      currentPage: 1,
      itemsPerPage: 10,
      sortedColumn: null,
      sortOrder: "asc",
      modalOpen: false,
      modalNoteOpen: false,
      totalBookCopies:'',
    };
  }

  async componentDidMount() {
    // for all orders items
    const orderId = localStorage.getItem("selectedOrderId");
    const aid = orderId;
    console.log(orderId + "............." + aid);
    const response = await this.props.listPrintBookOrderDetails({
      orderBookId: aid,
    });

    this.setState({
       selectedUserData: response.payload.orderBookDetail,
       selectedPackageData: response.payload.userPackages ,
       orderItemsData: response.payload.orderItemDetails,
       totalBookCopies: response.payload.totalBookCopies,
       }, () =>{

       });

    // for success orders items
    // const successResponse = await this.props.listPrintBookOrderDetails({
    //   orderId: aid,
    //   search: '{"status": "completed"}',
    // });
    // this.setState({ successItemsData: successResponse.payload.orderItemDetails });
    // // this.setState({ successOrderItem: successResponse.payload.order_items.orderItems });
    // console.log("Success Response data:", successResponse);
    // // for pending orders items
    // const pendingResponse = await this.props.listPrintBookOrderDetails({
    //   orderId: aid,
    //   search: '{"status": "order placed"}',
    // });
    // this.setState({ pendingItemsData: pendingResponse.payload.orderItemDetails });
    // // for processing orders items
    // const processingResponse = await this.props.listPrintBookOrderDetails({
    //   orderId: aid,
    //   search: '{"status": "printing"}',
    // });
    // this.setState({
    //   processingItemsData: processingResponse.payload.orderItemDetails,
    // });
    // // for shipped orders items
    // const shippedResponse = await this.props.listPrintBookOrderDetails({
    //   orderId: aid,
    //   search: '{"status": "transit"}',
    // });
    // this.setState({ shippedItemsData: shippedResponse.payload.orderItemDetails });
  
  }

  sendEmail = async (e) => {
    e.preventDefault();
    try {
      const { selectedUserData } = this.state;
      const response = await this.props.sendEmail({
        to: selectedUserData.user_email,
        subject: "Order Details",
      });
      if (response.status === 200) {
        this.setState((prevState) => ({
          modalOpen: false,
        }));
      }
      toast.success("Email send successfully.", { autoClose: 10000 });
    } catch (err) {
      toast.error("Something went wrong.", { autoClose: 10000 });
      console.log(err);
    }
  };

  generateExcelData = () => {
    const { orderItemsData } = this.state;
    const xlsxData = [
      [
        "Items",
        "First & Last Name",
        "Email",
        "Reference No.",
        "Amount",
        "Status",
        "Print Book Order Date",
        "Types Of Book",
        "Notes",
        "Delivery Address",
      ],
    ];
    for (const data of orderItemsData) {
      const dataRow = [
        data.order_item_name,
        data.id,
        data.amount,
        data.status,
        formatDateAndTime(data.date),
        // data.device,
        data.ip_address,
        data.payment_method,
      ];
      xlsxData.push(dataRow);
    }
    const worksheet = XLSX.utils.aoa_to_sheet(xlsxData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    return workbook;
  };

  handleDownload = () => {
    const workbook = this.generateExcelData();
    XLSX.writeFile(workbook, "order_details.xlsx");
  };

  handleOrderReceiptClick = () => {
    window.open("/order-management/order-details/order-receipt/", "_self");
  };

  handleShowDetails = (remarks) => {
    this.setState((prevState) => ({
      modalOpen: !prevState.modalOpen,
      remarks: remarks,
    }));
  };

  onCloseModal = () => {
    this.setState((prevState) => ({
      modalOpen: false,
    }));
  };

  handlePageChange = (pageNumber) => {
    this.setState({ currentPage: pageNumber });
  };

  handleTableSort = (column) => {
    const { orderItemsData, sortedColumn, sortOrder } = this.state;
    // Toggle the sortOrder if the same column is clicked again
    const newSortOrder =
      column === sortedColumn && sortOrder === "asc" ? "desc" : "asc";

    // Sort the data based on the selected column and sortOrder
    const sortedData = orderItemsData.slice().sort((a, b) => {
      if (newSortOrder === "asc") {
        return a[column] > b[column] ? 1 : -1;
      } else {
        return a[column] < b[column] ? 1 : -1;
      }
    });
    this.setState({
      orderItemsData: sortedData,
      sortedColumn: column,
      sortOrder: newSortOrder,
    });
  };

  updateNotes = async (e) => {
    e.preventDefault();
    try {
      const orderId = localStorage.getItem("selectedOrderId");
      const aid = parseFloat(orderId);
      const { selectedUserData } = this.state;
      const response = await this.props.updateNote({
        userId: selectedUserData.id,
        id: aid,
        note: selectedUserData.note,
      });
      if (response.status === 200) {
        this.setState((prevState) => ({
          modalNoteOpen: false,
        }));
      }
      toast.success("Notes updated successfully.", { autoClose: 10000 });
      window.open("/order-management/order-details/", "_self");
    } catch (err) {
      toast.error("Something went wrong.", { autoClose: 10000 });
      console.log(err);
    }
  };

  handleInput = async (e) => {
    try {
      let tid = e.target.id;
      const { selectedUserData } = this.state;
      switch (tid) {
        case "note":
          const note = e.target.value;
          this.setState({
            selectedUserData: {
              ...selectedUserData,
              note,
            },
          });
          break;
        default:
          console.log("No Matching target found in handleInput");
          break;
      }
    } catch (err) {
      console.log(err);
    }
  };

  handleShowNotes = () => {
    this.setState((prevState) => ({
      modalNoteOpen: !prevState.modalNoteOpen,
    }));
  };

  onCloseNotesModal = () => {
    this.setState((prevState) => ({
      modalNoteOpen: false,
    }));
  };

  render() {
    const {
      BasicTab,
      modalOpen,
      modalNoteOpen,
      successItemsData,
      pendingItemsData,
      processingItemsData,
      shippedItemsData,
      selectedPackageData,
      canceledItemsData,
      selectedUserData,
      selectedBuyerData,
      orderItemsData,
      itemData,
      currentPage,
      itemsPerPage,
      sortedColumn,
      sortOrder,
      totalBookCopies,
      orderBookDetail,
      additionalOrderDetails,
    } = this.state;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentData = orderItemsData.slice(indexOfFirstItem, indexOfLastItem);
    // const currentData = Array.isArray(orderItemsData) 
    // ? orderItemsData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
    // : [];
  //   const currentData = Array.isArray(orderItemsData)
  // ? orderItemsData.slice(indexOfFirstItem, indexOfLastItem)
  // : [];

    // console.log("selectedPackageData===", selectedPackageData);
    // const isDataAvailable = selectedBuyerData.name || selectedBuyerData.user_email || selectedBuyerData.address || selectedBuyerData.total_payment || selectedBuyerData.buyer_status || selectedBuyerData.language_id || selectedBuyerData.note;

    const totalPages = Math.ceil(orderItemsData.length / itemsPerPage);
    // const totalPages = Math.ceil((Array.isArray(orderItemsData) ? orderItemsData.length : 0) / itemsPerPage);

    return (
      <Fragment>
        <ToastContainer />
        <div className="page-title">
          <Row>
            <Col xs="12">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/dashboard/">
                    <SvgIcon iconId="stroke-home" />
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link style={{ color: "#000" }} to="/order-management/">
                    Printing Book Order
                  </Link>
                </li>
                <li className="breadcrumb-item active">Printing Book Order</li>
              </ol>
            </Col>
          </Row>
        </div>
        <Col sm="12" xl="12" className="xl-100">
          <Card>
            <CardHeader className="d-flex align-items-center justify-content-between">
              <H4>Printing Book Order</H4>
              <img
                src={excelImg}
                width="35"
                height="35"
                alt="excel"
                onClick={this.handleDownload}
              ></img>
            </CardHeader>
            <CardBody>
              <Form>
                <Row>
                  <Col md="6" style={{ display: "flex", alignItems: "center" }}>
                    <img
                      src={userCircle}
                      width="22"
                      height="22"
                      alt="excel"
                      style={{ marginRight: "10px" }}
                    />
                    <H5 style={{ margin: 0 }}>User Details</H5>
                  </Col>

                  <Col md="6" style={{ display: "flex", alignItems: "center" }}>
                    <img
                      src={userCircle}
                      width="22"
                      height="22"
                      alt="excel"
                      style={{ marginRight: "10px" }}
                    />
                    <H5 style={{ margin: 0 }}>Package Details</H5>
                  </Col>
                </Row>
                <Row>
                  <Col
                    md="3"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      color: "#595959",
                      marginTop: 15,
                    }}
                  >
                    <div>
                      <div className="mb-2">Name</div>
                      <div className="mb-2">Email</div>
                      <div className="mb-2">Address</div>
                      <div className="mb-2">Total Payment</div>
                      <div className="mb-2">Total Book Copy</div>
                      <div className="mb-2">Print Book Date</div>
                      <div className="mb-2">Notes</div>
                    </div>
                  </Col>
                  <Col
                    md="3"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: 15,
                    }}
                  >
                    <div>
                      <div className="mb-2">
                        {selectedUserData.user_name || ""}
                      </div>
                      <div className="mb-2">{selectedUserData.email || ""}</div>
                      <div className="mb-2">
                        {selectedUserData.address || ""},{" "}
                        {selectedUserData.city || ""},{" "}
                        {selectedUserData.state || ""},{" "}
                        {selectedUserData.postcode || ""}
                      </div>
                      <div className="mb-2">
                        £{selectedUserData.subtotal || ""}
                      </div>
                      <div className="mb-2">
                        {totalBookCopies || ""}
                      </div>
                      <div className="mb-2">
                        {selectedUserData.created_date || ""}
                      </div>
                      <div className="mb-4">
                        <Input
                          type="textarea"
                          placeholder="Notes"
                          value={selectedUserData.note || ""}
                          onClick={this.handleShowNotes}
                        />
                      </div>
                    </div>
                  </Col>

                  <Col
                    md="3"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      color: "#595959",
                      marginTop: 15,
                    }}
                  >
                    <div>
                      <div className="mb-2">Package</div>
                      <div className="mb-2">Purchase Date</div>
                      <div className="mb-2">Book Expiry Date</div>
                    </div>
                  </Col>
                  <Col
                    md="3"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: 15,
                    }}
                  >
                    {selectedPackageData.map((data, i) => (
                      <div>
                        <div className="mb-2">
                          {data.packages[0].package || ""}
                        </div>
                        <div className="mb-2">{data.orderDate || ""}</div>
                        <div className="mb-2">{data.expiryDate || ""}</div>
                      </div>
                    ))}
                  </Col>
                  {/* </>
                  )} */}
                  {/* </>)} */}
                </Row>
                <Modal
                  isOpen={modalNoteOpen}
                  toggle={() => this.handleShowNotes()}
                  style={{ marginTop: "200px" }}
                >
                  <Form onSubmit={this.updateNotes}>
                    <span
                      style={{ textAlign: "right", padding: "5px" }}
                      onClick={this.onCloseNotesModal}
                    >
                      Close
                    </span>
                    <ModalBody>
                      <div
                        style={{
                          marginTop: "-10px",
                          marginBottom: "20px",
                          padding: "7px",
                          background: "#890077",
                          color: "#fff",
                          height: "30px",
                          width: "100%",
                          borderRadius: "5px",
                        }}
                      >
                        Update Notes
                      </div>
                      <Row>
                        <Col md="2"></Col>
                        <Col md="8">
                          <Label>Notes</Label>
                          <Input
                            style={{ fontSize: "10px", padding: "11px" }}
                            type="textarea"
                            id="note"
                            name="note"
                            value={selectedUserData.note || ""}
                            onChange={this.handleInput}
                          />
                        </Col>
                      </Row>
                    </ModalBody>
                    <ModalFooter>
                      <Col md="4"></Col>&nbsp;&nbsp;&nbsp;&nbsp;
                      <Col>
                        <Btn attrBtn={{ color: "primary", type: "submit" }}>
                          Update
                        </Btn>
                        &nbsp;&nbsp;
                      </Col>
                    </ModalFooter>
                  </Form>
                </Modal>
              </Form>
              <Row>
                <Col md="12" style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={list}
                    width="22"
                    height="22"
                    alt="excel"
                    style={{ marginRight: "10px" }}
                  />
                  <H5 style={{ margin: 0 }}>Order Items</H5>
                </Col>
              </Row>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    href="#javascript"
                    className={BasicTab === "1" ? "active" : ""}
                    onClick={() => this.setState({ BasicTab: "1" })}
                  >
                    All
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="#javascript"
                    className={BasicTab === "2" ? "active" : ""}
                    onClick={() => this.setState({ BasicTab: "2" })}
                  >
                    Order Placed
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="#javascript"
                    className={BasicTab === "3" ? "active" : ""}
                    onClick={() => this.setState({ BasicTab: "3" })}
                  >
                    Printing
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="#javascript"
                    className={BasicTab === "4" ? "active" : ""}
                    onClick={() => this.setState({ BasicTab: "4" })}
                  >
                    Transit
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="#javascript"
                    className={BasicTab === "5" ? "active" : ""}
                    onClick={() => this.setState({ BasicTab: "5" })}
                  >
                    Completed
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={BasicTab}>
                <TabPane className="fade show" tabId="1">
                  <Table
                    ref="tbl"
                    striped
                    hover
                    responsive
                    bordered
                    id="data-table-zero"
                  >
                    <thead>
                      <tr>
                        <th
                          onClick={() =>
                            this.handleTableSort("order_item_name")
                          }
                        >
                          ITEMS{" "}
                          {sortedColumn === "order_item_name" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th
                          onClick={() =>
                            this.handleTableSort("first_&_last_name")
                          }
                        >
                          First & Last Name{" "}
                          {sortedColumn === "first_&_last_name" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        {/* <th onClick={() => this.handleTableSort("email")}>
                          Email{" "}
                          {sortedColumn === "email" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th> */}
                        <th onClick={() => this.handleTableSort("id")}>
                          Reference No.{" "}
                          {sortedColumn === "id" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th onClick={() => this.handleTableSort("amount")}>
                          Amount{" "}
                          {sortedColumn === "amount" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th onClick={() => this.handleTableSort("status")}>
                          Status{" "}
                          {sortedColumn === "status" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th onClick={() => this.handleTableSort("date")}>
                          Print Book Order Date{" "}
                          {sortedColumn === "date" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th
                          onClick={() => this.handleTableSort("type_of_book")}
                        >
                          Type Of Book{" "}
                          {sortedColumn === "type_of_book" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        {/* <th onClick={() => this.handleTableSort("notes")}>
                          Notes{" "}
                          {sortedColumn === "notes" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th> */}
                        <th
                          onClick={() =>
                            this.handleTableSort("delivery_address")
                          }
                        >
                          Delivery Address{" "}
                          {sortedColumn === "delivery_address" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th
                          onClick={() => this.handleTableSort("book_download")}
                        >
                          Book Download{" "}
                          {sortedColumn === "book_download" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentData.map((data, i) =>
                          <tr key={ data.id ||i}>
                            <td>{data.itemName}</td>
                            <td>{data.first_name} {data.last_name}</td>
                            <td>{data.id}</td>
                            <td>£{parseFloat(data.subtotal).toFixed(2)}</td>
                            {data.status === "Pending" ? (
                              <td>
                                <span className="badge badge-light-info">
                                  {data.status}
                                </span>
                              </td>
                            ) : (
                              <td>
                                <span className="badge badge-light-primary">
                                  {data.status}
                                </span>
                              </td>
                            )}
                            <td>{formatDateAndTime(data.created_date)}</td>
                            <td>{data.printColor.coverColor}</td>
                            <td>{data.address} {data.address2} {data.city} {data.state} {data.postcode}</td>
                            <td>
                              <img
                                src={receipt}
                                alt="receipt"
                                height={15}
                                width={15}
                                onClick={this.handleOrderReceiptClick}
                              />
                            </td>
                            <td>
                              <img
                                src={icon}
                                alt="icon"
                                height={15}
                                width={15}
                                onClick={this.handleShowDetails}
                              />
                            </td>
                          </tr>
                        
                      )}
                    </tbody>
                  </Table>
                </TabPane>
                <TabPane tabId="2">
                  <Table
                    ref="tbl"
                    striped
                    hover
                    responsive
                    bordered
                    id="data-table-zero"
                  >
                    <thead>
                      <tr>
                        <th
                          onClick={() =>
                            this.handleTableSort("order_item_name")
                          }
                        >
                          ITEMS{" "}
                          {sortedColumn === "order_item_name" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th
                          onClick={() =>
                            this.handleTableSort("first_&_last_name")
                          }
                        >
                          First & Last Name{" "}
                          {sortedColumn === "first_&_last_name" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th onClick={() => this.handleTableSort("email")}>
                          Email{" "}
                          {sortedColumn === "email" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th onClick={() => this.handleTableSort("id")}>
                          Reference No.{" "}
                          {sortedColumn === "id" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th onClick={() => this.handleTableSort("amount")}>
                          Amount{" "}
                          {sortedColumn === "amount" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th onClick={() => this.handleTableSort("status")}>
                          Status{" "}
                          {sortedColumn === "status" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th onClick={() => this.handleTableSort("date")}>
                          Print Book Order Date{" "}
                          {sortedColumn === "date" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th
                          onClick={() => this.handleTableSort("type_of_book")}
                        >
                          Type Of Book{" "}
                          {sortedColumn === "type_of_book" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th onClick={() => this.handleTableSort("notes")}>
                          Notes{" "}
                          {sortedColumn === "notes" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th
                          onClick={() =>
                            this.handleTableSort("delivery_address")
                          }
                        >
                          Delivery Address{" "}
                          {sortedColumn === "delivery_address" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th
                          onClick={() => this.handleTableSort("book_download")}
                        >
                          Book Download{" "}
                          {sortedColumn === "book_download" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentData.map((data, i) =>
                          <tr key={ data.id ||i}>
                            <td>{data.itemName}</td>
                            <td>{data.first_name} {data.last_name}</td>
                            <td>{data.id}</td>
                            <td>£{parseFloat(data.subtotal).toFixed(2)}</td>
                            {data.status === "Pending" ? (
                              <td>
                                <span className="badge badge-light-info">
                                  {data.status}
                                </span>
                              </td>
                            ) : (
                              <td>
                                <span className="badge badge-light-primary">
                                  {data.status}
                                </span>
                              </td>
                            )}
                            <td>{formatDateAndTime(data.created_date)}</td>
                            <td>{data.printColor.coverColor}</td>
                            <td>{data.address} {data.address2} {data.city} {data.state} {data.postcode}</td>
                            <td>
                              <img
                                src={receipt}
                                alt="receipt"
                                height={15}
                                width={15}
                                onClick={this.handleOrderReceiptClick}
                              />
                            </td>
                            <td>
                              <img
                                src={icon}
                                alt="icon"
                                height={15}
                                width={15}
                                onClick={this.handleShowDetails}
                              />
                            </td>
                          </tr>
                        
                      )}
                    </tbody>
                    {/* <tbody>
                      {successItemsData.map((data, i) =>
                        itemData.map((item, j) => (
                          <tr key={`${i}-${j}`}>
                            <td>{item.order_item_name}</td>
                            <td>{item.book_user_buyer_name}</td>
                            <td>{item.book_user_owner_name}</td>
                            <td>{data.id}</td>
                            <td>{item.price}</td>
                            <td>
                              <span
                                className="badge badge-light-primary"
                                style={{
                                  background: "#b3ffb3",
                                  color: "#00b300",
                                }}
                              >
                                {data.status}
                              </span>
                            </td>
                            <td>{formatDateAndTime(data.date_created_gmt)}</td>
                            <td>{data.ip_address}</td>
                            <td>{data.payment_method}</td>
                            <td>
                              <img
                                src={receipt}
                                alt="receipt"
                                height={15}
                                width={15}
                                onClick={this.handleOrderReceiptClick}
                              />
                            </td>
                            <td>
                              <img
                                src={icon}
                                alt="icon"
                                height={15}
                                width={15}
                                onClick={this.handleShowDetails}
                              />
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody> */}
                  </Table>
                </TabPane>
                <TabPane tabId="3">
                  <Table
                    ref="tbl"
                    striped
                    hover
                    responsive
                    bordered
                    id="data-table-zero"
                  >
                    <thead>
                      <tr>
                        <th
                          onClick={() =>
                            this.handleTableSort("order_item_name")
                          }
                        >
                          ITEMS{" "}
                          {sortedColumn === "order_item_name" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th
                          onClick={() =>
                            this.handleTableSort("first_&_last_name")
                          }
                        >
                          First & Last Name{" "}
                          {sortedColumn === "first_&_last_name" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th onClick={() => this.handleTableSort("email")}>
                          Email{" "}
                          {sortedColumn === "email" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th onClick={() => this.handleTableSort("id")}>
                          Reference No.{" "}
                          {sortedColumn === "id" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th onClick={() => this.handleTableSort("amount")}>
                          Amount{" "}
                          {sortedColumn === "amount" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th onClick={() => this.handleTableSort("status")}>
                          Status{" "}
                          {sortedColumn === "status" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th onClick={() => this.handleTableSort("date")}>
                          Print Book Order Date{" "}
                          {sortedColumn === "date" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th
                          onClick={() => this.handleTableSort("type_of_book")}
                        >
                          Type Of Book{" "}
                          {sortedColumn === "type_of_book" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th onClick={() => this.handleTableSort("notes")}>
                          Notes{" "}
                          {sortedColumn === "notes" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th
                          onClick={() =>
                            this.handleTableSort("delivery_address")
                          }
                        >
                          Delivery Address{" "}
                          {sortedColumn === "delivery_address" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th
                          onClick={() => this.handleTableSort("book_download")}
                        >
                          Book Download{" "}
                          {sortedColumn === "book_download" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentData.map((data, i) =>
                          <tr key={ data.id ||i}>
                            <td>{data.itemName}</td>
                            <td>{data.first_name} {data.last_name}</td>
                            <td>{data.id}</td>
                            <td>£{parseFloat(data.subtotal).toFixed(2)}</td>
                            {data.status === "Pending" ? (
                              <td>
                                <span className="badge badge-light-info">
                                  {data.status}
                                </span>
                              </td>
                            ) : (
                              <td>
                                <span className="badge badge-light-primary">
                                  {data.status}
                                </span>
                              </td>
                            )}
                            <td>{formatDateAndTime(data.created_date)}</td>
                            <td>{data.printColor.coverColor}</td>
                            <td>{data.address} {data.address2} {data.city} {data.state} {data.postcode}</td>
                            <td>
                              <img
                                src={receipt}
                                alt="receipt"
                                height={15}
                                width={15}
                                onClick={this.handleOrderReceiptClick}
                              />
                            </td>
                            <td>
                              <img
                                src={icon}
                                alt="icon"
                                height={15}
                                width={15}
                                onClick={this.handleShowDetails}
                              />
                            </td>
                          </tr>
                        
                      )}
                    </tbody>
                    {/* <tbody>
                      {pendingItemsData.map((data, i) =>
                        itemData.map((item, j) => (
                          <tr key={`${i}-${j}`}>
                            <td>{item.order_item_name}</td>
                            <td>{item.book_user_buyer_name}</td>
                            <td>{item.book_user_owner_name}</td>
                            <td>{data.id}</td>
                            <td>{item.price}</td>
                            {data.status === "wc-pending" ? (
                              <td>
                                <span className="badge badge-light-info">
                                  {data.status}
                                </span>
                              </td>
                            ) : (
                              <td>
                                <span className="badge badge-light-primary">
                                  {data.status}
                                </span>
                              </td>
                            )}
                            <td>{formatDateAndTime(data.date_created_gmt)}</td>
                            <td>{data.ip_address}</td>
                            <td>{data.payment_method}</td>
                            <td>
                              <img
                                src={receipt}
                                alt="receipt"
                                height={15}
                                width={15}
                                onClick={this.handleOrderReceiptClick}
                              />
                            </td>
                            <td>
                              <img
                                src={icon}
                                alt="icon"
                                height={15}
                                width={15}
                                onClick={this.handleShowDetails}
                              />
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody> */}
                  </Table>
                </TabPane>
                <TabPane tabId="4">
                  <Table
                    ref="tbl"
                    striped
                    hover
                    responsive
                    bordered
                    id="data-table-zero"
                  >
                    <thead>
                      <tr>
                        <th
                          onClick={() =>
                            this.handleTableSort("order_item_name")
                          }
                        >
                          ITEMS{" "}
                          {sortedColumn === "order_item_name" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th
                          onClick={() =>
                            this.handleTableSort("first_&_last_name")
                          }
                        >
                          First & Last Name{" "}
                          {sortedColumn === "first_&_last_name" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th onClick={() => this.handleTableSort("email")}>
                          Email{" "}
                          {sortedColumn === "email" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th onClick={() => this.handleTableSort("id")}>
                          Reference No.{" "}
                          {sortedColumn === "id" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th onClick={() => this.handleTableSort("amount")}>
                          Amount{" "}
                          {sortedColumn === "amount" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th onClick={() => this.handleTableSort("status")}>
                          Status{" "}
                          {sortedColumn === "status" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th onClick={() => this.handleTableSort("date")}>
                          Print Book Order Date{" "}
                          {sortedColumn === "date" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th
                          onClick={() => this.handleTableSort("type_of_book")}
                        >
                          Type Of Book{" "}
                          {sortedColumn === "type_of_book" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th onClick={() => this.handleTableSort("notes")}>
                          Notes{" "}
                          {sortedColumn === "notes" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th
                          onClick={() =>
                            this.handleTableSort("delivery_address")
                          }
                        >
                          Delivery Address{" "}
                          {sortedColumn === "delivery_address" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th
                          onClick={() => this.handleTableSort("book_download")}
                        >
                          Book Download{" "}
                          {sortedColumn === "book_download" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentData.map((data, i) =>
                          <tr key={ data.id ||i}>
                            <td>{data.itemName}</td>
                            <td>{data.first_name} {data.last_name}</td>
                            <td>{data.id}</td>
                            <td>£{parseFloat(data.subtotal).toFixed(2)}</td>
                            {data.status === "Pending" ? (
                              <td>
                                <span className="badge badge-light-info">
                                  {data.status}
                                </span>
                              </td>
                            ) : (
                              <td>
                                <span className="badge badge-light-primary">
                                  {data.status}
                                </span>
                              </td>
                            )}
                            <td>{formatDateAndTime(data.created_date)}</td>
                            <td>{data.printColor.coverColor}</td>
                            <td>{data.address} {data.address2} {data.city} {data.state} {data.postcode}</td>
                            <td>
                              <img
                                src={receipt}
                                alt="receipt"
                                height={15}
                                width={15}
                                onClick={this.handleOrderReceiptClick}
                              />
                            </td>
                            <td>
                              <img
                                src={icon}
                                alt="icon"
                                height={15}
                                width={15}
                                onClick={this.handleShowDetails}
                              />
                            </td>
                          </tr>
                        
                      )}
                    </tbody>
                    {/* <tbody>
                      {processingItemsData.map((data, i) =>
                        itemData.map((item, j) => (
                          <tr key={`${i}-${j}`}>
                            <td>{item.order_item_name}</td>
                            <td>{item.book_user_buyer_name}</td>
                            <td>{item.book_user_owner_name}</td>
                            <td>{data.id}</td>
                            <td>{item.price}</td>
                            <td>
                              <span
                                className="badge badge-light-primary"
                                style={{
                                  background: "#ffffcc",
                                  color: "#808000",
                                }}
                              >
                                {data.status}
                              </span>
                            </td>
                            <td>{formatDateAndTime(data.date_created_gmt)}</td>
                            <td>{data.ip_address}</td>
                            <td>{data.payment_method}</td>
                            <td>
                              <img
                                src={receipt}
                                alt="receipt"
                                height={15}
                                width={15}
                                onClick={this.handleOrderReceiptClick}
                              />
                            </td>
                            <td>
                              <img
                                src={icon}
                                alt="icon"
                                height={15}
                                width={15}
                                onClick={this.handleShowDetails}
                              />
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody> */}
                  </Table>
                </TabPane>
                <TabPane tabId="5">
                  <Table
                    ref="tbl"
                    striped
                    hover
                    responsive
                    bordered
                    id="data-table-zero"
                  >
                    <thead>
                      <tr>
                        <th
                          onClick={() =>
                            this.handleTableSort("order_item_name")
                          }
                        >
                          ITEMS{" "}
                          {sortedColumn === "order_item_name" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th
                          onClick={() =>
                            this.handleTableSort("first_&_last_name")
                          }
                        >
                          First & Last Name{" "}
                          {sortedColumn === "first_&_last_name" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th onClick={() => this.handleTableSort("email")}>
                          Email{" "}
                          {sortedColumn === "email" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th onClick={() => this.handleTableSort("id")}>
                          Reference No.{" "}
                          {sortedColumn === "id" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th onClick={() => this.handleTableSort("amount")}>
                          Amount{" "}
                          {sortedColumn === "amount" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th onClick={() => this.handleTableSort("status")}>
                          Status{" "}
                          {sortedColumn === "status" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th onClick={() => this.handleTableSort("date")}>
                          Print Book Order Date{" "}
                          {sortedColumn === "date" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th
                          onClick={() => this.handleTableSort("type_of_book")}
                        >
                          Type Of Book{" "}
                          {sortedColumn === "type_of_book" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th onClick={() => this.handleTableSort("notes")}>
                          Notes{" "}
                          {sortedColumn === "notes" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th
                          onClick={() =>
                            this.handleTableSort("delivery_address")
                          }
                        >
                          Delivery Address{" "}
                          {sortedColumn === "delivery_address" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                        <th
                          onClick={() => this.handleTableSort("book_download")}
                        >
                          Book Download{" "}
                          {sortedColumn === "book_download" && (
                            <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                          )}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentData.map((data, i) =>
                          <tr key={ data.id ||i}>
                            <td>{data.itemName}</td>
                            <td>{data.first_name} {data.last_name}</td>
                            <td>{data.id}</td>
                            <td>£{parseFloat(data.subtotal).toFixed(2)}</td>
                            {data.status === "Pending" ? (
                              <td>
                                <span className="badge badge-light-info">
                                  {data.status}
                                </span>
                              </td>
                            ) : (
                              <td>
                                <span className="badge badge-light-primary">
                                  {data.status}
                                </span>
                              </td>
                            )}
                            <td>{formatDateAndTime(data.created_date)}</td>
                            <td>{data.printColor.coverColor}</td>
                            <td>{data.address} {data.address2} {data.city} {data.state} {data.postcode}</td>
                            <td>
                              <img
                                src={receipt}
                                alt="receipt"
                                height={15}
                                width={15}
                                onClick={this.handleOrderReceiptClick}
                              />
                            </td>
                            <td>
                              <img
                                src={icon}
                                alt="icon"
                                height={15}
                                width={15}
                                onClick={this.handleShowDetails}
                              />
                            </td>
                          </tr>
                        
                      )}
                    </tbody>
                    {/* <tbody>
                      {shippedItemsData.map((data, i) =>
                        itemData.map((item, j) => (
                          <tr key={`${i}-${j}`}>
                            <td>{item.order_item_name}</td>
                            <td>{item.book_user_buyer_name}</td>
                            <td>{item.book_user_owner_name}</td>
                            <td>{data.id}</td>
                            <td>{item.price}</td>
                            <td>
                              <span
                                className="badge badge-light-primary"
                                style={{
                                  background: "#ffffcc",
                                  color: "#808000",
                                }}
                              >
                                {data.status}
                              </span>
                            </td>
                            <td>{formatDateAndTime(data.date_created_gmt)}</td>
                            <td>{data.ip_address}</td>
                            <td>{data.payment_method}</td>
                            <td>
                              <img
                                src={receipt}
                                alt="receipt"
                                height={15}
                                width={15}
                                onClick={this.handleOrderReceiptClick}
                              />
                            </td>
                            <td>
                              <img
                                src={icon}
                                alt="icon"
                                height={15}
                                width={15}
                                onClick={this.handleShowDetails}
                              />
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody> */}
                  </Table>
                </TabPane>
              </TabContent>
              <Modal
                isOpen={modalOpen}
                toggle={() => this.handleShowDetails()}
                style={{ marginTop: "200px" }}
              >
                <Form onSubmit={this.sendEmail}>
                  <span
                    style={{ textAlign: "right", padding: "5px" }}
                    onClick={this.onCloseModal}
                  >
                    Close
                  </span>
                  <ModalBody>
                    <div
                      style={{
                        marginTop: "-10px",
                        marginBottom: "20px",
                        padding: "7px",
                        background: "#890077",
                        color: "#fff",
                        height: "30px",
                        width: "100%",
                        borderRadius: "5px",
                      }}
                    >
                      Send Email
                    </div>
                    <Row>
                      <Col md="2"></Col>
                      <Col md="8">
                        <Label>Email</Label>
                        <Input
                          style={{ fontSize: "10px", padding: "11px" }}
                          type="text"
                          value={selectedUserData.user_email}
                          disabled
                        />
                      </Col>
                    </Row>
                  </ModalBody>
                  <ModalFooter>
                    <Col md="4"></Col>&nbsp;&nbsp;&nbsp;&nbsp;
                    <Col>
                      <Btn attrBtn={{ color: "primary", type: "submit" }}>
                        Send
                      </Btn>
                      &nbsp;&nbsp;
                    </Col>
                  </ModalFooter>
                </Form>
              </Modal>
            </CardBody>
            <CardFooter>
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={this.handlePageChange}
              />
            </CardFooter>
          </Card>
        </Col>
      </Fragment>
    );
  }
}
const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {
  listPrintBookOrderDetails: printBookOrderOperations.listPrintBookOrderDetails,
  // updateNote: customerOperations.updateNote,
  // sendEmail: masterOperations.sendEmail
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrintingBookDetails);
