import React, { Fragment } from 'react';
import { Search } from 'react-feather';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Table } from 'react-bootstrap';
import * as XLSX from 'xlsx';
import { Container, Card, CardBody, CardHeader, CardFooter, Col, Nav, NavItem, NavLink, TabContent, TabPane, Input, Form, Row } from 'reactstrap';
import { H5 } from '../../AbstractElements';
import Pagination from '../Pagination';
import '../../assets/scss/pagination.css';
import excelImg from '../../assets/images/datatable/excel.png';
import SvgIcon from '../../Components/Common/Component/SvgIcon';
import { customerOperations } from '../../state/ducks/customer';
import { masterOperations } from '../../state/ducks/master';
import viewIcon from '../../assets/images/datatable/list-details.png';
import { formatDateAndTime, getLanguageName } from '../../utils/helper';

class Customer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      BasicTab: '1',
      language: [],
      customerData: [],
      giftCustomerData:[],
      activeUsers: 0,
      inactiveUsers: 0,
      activeCustomerData: [],
      inactiveCustomerData: [],
      defaultSearchData: [],
      searchData: '',
      currentPage: 1,
      itemsPerPage: 10,
      sortedColumn: null,
      sortOrder: 'asc'
    };
  }

  async componentDidMount() {
    try {
      this.loadLocalStorageData();
      
      // Fetch all customers data
      const response = await this.props.listCustomer();
      // Calculate active and inactive user counts
      const activeCustomerData = response.payload.customers.filter(customer => customer.customer_activity === 'active');
      const inactiveCustomerData = response.payload.customers.filter(customer => customer.customer_activity === 'inactive');
 
      // Set customer data and counts in state
      this.setState({
        customerData: response.payload.customers,
        giftCustomerData: response.payload.giftCustomers,
        defaultSearchData: response.payload.customers,
        activeUsers: activeCustomerData.length,
        inactiveUsers: inactiveCustomerData.length,
        activeCustomerData,
        inactiveCustomerData
      });
  
    } catch (err) {
      console.log(err);
    }
  }

  loadLocalStorageData = async () => {
    // Store Language
    let language = localStorage.getItem('language');
    if (language) {
      language = JSON.parse(language);
      this.setState({ language });
    } else {
      let response = await this.props.listLanguage();
      if (response.payload.length !== 0) {
        const languages = JSON.stringify(response.payload);
        localStorage.setItem('language', languages);
        language = JSON.parse(languages);
        this.setState({ language });
      }
    }
  }

  generateExcelData = () => {
    const { customerData } = this.state;
    const xlsxData = [
      ['Name', 'Book User', 'Email', 'Creation Date', 'Note', 'Language']
    ];
    for (const data of customerData) {
      const dataRow = [
        data.name,
        data.book_user,
        data.user_email,
        // data.phone_no,
        formatDateAndTime(data.created_date),
        data.note,
        data.language_id,
        getLanguageName(data.language_id)
      ];
      xlsxData.push(dataRow);
    }
    const worksheet = XLSX.utils.aoa_to_sheet(xlsxData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    return workbook;
  };

  handleDownload = () => {
    const workbook = this.generateExcelData();
    XLSX.writeFile(workbook, 'customer.xlsx');
  };

  handleViewDetailsClick = (e) => {
    localStorage.setItem("selectedCustomerId", e.target.getAttribute('data-test-id'));
    window.open("/customers-management/customer-details/", "_self");
  }

  handlePageChange = (pageNumber) => {
    this.setState({ currentPage: pageNumber });
  };

  DefaultSearchChange = (e) => {
    const searchData = e.target.value;
    const defaultSearchData = this.state.customerData.filter(data => {
      const valuesToSearch = Object.values(data).filter(value => value !== null);
      return valuesToSearch.some(value =>
        String(value).toLowerCase().includes(searchData.toLowerCase())
      );
    });
    this.setState({ searchData, defaultSearchData });
  };

  handleTableSort = (column) => {
    const { defaultSearchData, sortedColumn, sortOrder } = this.state;
    // Toggle the sortOrder if the same column is clicked again
    const newSortOrder = column === sortedColumn && sortOrder === 'asc' ? 'desc' : 'asc';

    // Sort the data based on the selected column and sortOrder
    const sortedData = defaultSearchData.slice().sort((a, b) => {
      if (newSortOrder === 'asc') {
        return a[column] > b[column] ? 1 : -1;
      } else {
        return a[column] < b[column] ? 1 : -1;
      }
    });
    this.setState({
      defaultSearchData: sortedData,
      sortedColumn: column,
      sortOrder: newSortOrder,
    });
  };

  render() {
    const { BasicTab, activeUsers, inactiveUsers, activeCustomerData, inactiveCustomerData, giftCustomerData, defaultSearchData, currentPage, itemsPerPage, sortedColumn, sortOrder, searchData } = this.state;
    // For Pagination
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentData = defaultSearchData.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(defaultSearchData.length / itemsPerPage);
    
     // For Pagination on Active Customers
     const indexOfLastActiveItem = currentPage * itemsPerPage;
     const indexOfFirstActiveItem = indexOfLastActiveItem - itemsPerPage;
     const currentActiveData = activeCustomerData.slice(indexOfFirstActiveItem, indexOfLastActiveItem);
     const totalActivePages = Math.ceil(activeCustomerData.length / itemsPerPage);
 
     // For Pagination on Inactive Customers
     const indexOfLastInactiveItem = currentPage * itemsPerPage;
     const indexOfFirstInactiveItem = indexOfLastInactiveItem - itemsPerPage;
     const currentInactiveData = inactiveCustomerData.slice(indexOfFirstInactiveItem, indexOfLastInactiveItem);
     const totalInactivePages = Math.ceil(inactiveCustomerData.length / itemsPerPage);

    return (
      <Fragment>
        <div className='page-title'>
          <Row>
            <Col xs='12'>
              <ol className='breadcrumb'>
                <li className='breadcrumb-item'>
                  <Link to="/dashboard/">
                    <SvgIcon iconId='stroke-home' />
                  </Link>
                </li>
                <li className='breadcrumb-item active'>Customers</li>
              </ol>
            </Col>
          </Row>
        </div>
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              <Card>
                <CardHeader className='d-flex align-items-center justify-content-between'>
                  <H5>Customers</H5>
                  <img src={excelImg} width="35" height="35" alt='excel' onClick={this.handleDownload}></img>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row>
                      <Col md='9'></Col>
                      <Col md='3'>
                        <div className="job-filter">
                          <div className="faq-form">
                            <Input type="text" placeholder="Search" value={searchData} onChange={this.DefaultSearchChange} />
                            <Search className="search-icon" />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                  <Nav tabs>
                    <NavItem>
                      <NavLink href='#javascript' className={BasicTab === '1' ? 'active' : ''} onClick={() => this.setState({ BasicTab: '1' })}>
                        All Customers ({currentData.length})
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink href='#javascript' className={BasicTab === '2' ? 'active' : ''} onClick={() => this.setState({ BasicTab: '2' })}>
                        Active Customers ({currentInactiveData.length})
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink href='#javascript' className={BasicTab === '3' ? 'active' : ''} onClick={() => this.setState({ BasicTab: '3' })}>
                        Inactive Customers ({currentInactiveData.length})
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={BasicTab}>
                    <TabPane className='fade show' tabId='1'>
                      <Table ref="tbl" striped hover responsive bordered id="data-table-zero">
                        <thead>
                          <tr>
                            <th onClick={() => this.handleTableSort('name')}>Name {sortedColumn === 'name' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th onClick={() => this.handleTableSort('book_user')}>Book User {sortedColumn === 'book_user' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th onClick={() => this.handleTableSort('user_email')}>Email {sortedColumn === 'user_email' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            {/* <th onClick={() => this.handleTableSort('phone_no')}>Phone {sortedColumn === 'phone_no' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th> */}
                            <th onClick={() => this.handleTableSort('created_date')}>Creation Date {sortedColumn === 'created_date' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th onClick={() => this.handleTableSort('note')}>Note {sortedColumn === 'note' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th onClick={() => this.handleTableSort('language_id')}>Language {sortedColumn === 'language_id' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th>View Details</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentData.map((data, i) =>
                            <tr key={i}>
                              <td>{data.name}</td>
                              <td>
                                {data.book_user === "Owner" ? (
                                  <span className='badge badge-light-info'>Owner</span>
                                ) : data.book_user === "Buyer" ? (
                                  <span className='badge badge-light-primary'>Buyer</span>
                                ) : data.book_user === "Buyer Owner" ? (
                                  <span>
                                    <span className='badge badge-light-primary'>Buyer</span>
                                    <span className='badge badge-light-info'>Owner</span>
                                  </span>
                                ) : <span></span>}
                              </td>
                              <td>{data.user_email}</td>
                              {/* <td>{data.phone_no}</td> */}
                              <td>{data.created_date}</td>
                              <td>{data.note}</td>
                              <td>{data.language_id}</td>
                              {/* <td>{getLanguageName(data.language_id)}</td> */}
                              <td>
                                <img src={viewIcon} alt='view' height={19} width={19} data-test-id={data.id} onClick={this.handleViewDetailsClick} />
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </TabPane>
                    <TabPane className='fade show' tabId='2'>
                      <Table ref="tb1" striped hover responsive bordered id="data-table-zero">
                        <thead>
                          <tr>
                            <th onClick={() => this.handleTableSort('name')}>Name {sortedColumn === 'name' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th onClick={() => this.handleTableSort('book_user')}>Book User {sortedColumn === 'book_user' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th onClick={() => this.handleTableSort('user_email')}>Email {sortedColumn === 'user_email' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            {/* <th onClick={() => this.handleTableSort('phone_no')}>Phone {sortedColumn === 'phone_no' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th> */}
                            <th onClick={() => this.handleTableSort('created_date')}>Creation Date {sortedColumn === 'created_date' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th onClick={() => this.handleTableSort('note')}>Note {sortedColumn === 'note' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th onClick={() => this.handleTableSort('language_id')}>Language {sortedColumn === 'language_id' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th>View Details</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentActiveData.map((data, i) =>
                            <tr key={i}>
                              <td>{data.name}</td>
                              <td>
                                {data.book_user === "Owner" ? (
                                  <span className='badge badge-light-info'>Owner</span>
                                ) : data.book_user === "Buyer" ? (
                                  <span className='badge badge-light-primary'>Buyer</span>
                                ) : data.book_user === "Buyer Owner" ? (
                                  <span>
                                    <span className='badge badge-light-primary'>Buyer</span>
                                    <span className='badge badge-light-info'>Owner</span>
                                  </span>
                                ) : <span></span>}
                              </td>
                              <td>{data.user_email}</td>
                              {/* <td>{data.phone_no}</td> */}
                              <td>{data.created_date}</td>
                              <td>{data.note}</td>
                              <td>{getLanguageName(data.language_id)}</td>
                              <td>
                                <img src={viewIcon} alt='view' height={19} width={19} data-test-id={data.id} onClick={this.handleViewDetailsClick} />
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </TabPane >
                    <TabPane className='fade show' tabId='3'>
                      <Table ref="tbl" striped hover responsive bordered id="data-table-zero">
                        <thead>
                          <tr>
                            <th onClick={() => this.handleTableSort('name')}>Name {sortedColumn === 'name' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th onClick={() => this.handleTableSort('book_user')}>Book User {sortedColumn === 'book_user' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th onClick={() => this.handleTableSort('user_email')}>Email {sortedColumn === 'user_email' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            {/* <th onClick={() => this.handleTableSort('phone_no')}>Phone {sortedColumn === 'phone_no' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th> */}
                            <th onClick={() => this.handleTableSort('created_date')}>Creation Date {sortedColumn === 'created_date' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th onClick={() => this.handleTableSort('note')}>Note {sortedColumn === 'note' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th onClick={() => this.handleTableSort('language_id')}>Language {sortedColumn === 'language_id' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                            <th>View Details</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentInactiveData.map((data, i) =>
                            <tr key={i}>
                              <td>{data.name}</td>
                              <td>
                                {data.book_user === "Owner" ? (
                                  <span className='badge badge-light-info'>Owner</span>
                                ) : data.book_user === "Buyer" ? (
                                  <span className='badge badge-light-primary'>Buyer</span>
                                ) : data.book_user === "Buyer Owner" ? (
                                  <span>
                                    <span className='badge badge-light-primary'>Buyer</span>
                                    <span className='badge badge-light-info'>Owner</span>
                                  </span>
                                ) : <span></span>}
                              </td>
                              <td>{data.user_email}</td>
                              {/* <td>{data.phone_no}</td> */}
                              <td>{data.created_date}</td>
                              <td>{data.note}</td>
                              <td>{getLanguageName(data.language_id)}</td>
                              <td>
                                <img src={viewIcon} alt='view' height={19} width={19} data-test-id={data.id} onClick={this.handleViewDetailsClick} />
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </TabPane>
                  </TabContent>
                  <CardFooter>
                    <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={this.handlePageChange} />
                  </CardFooter>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Fragment >
    );
  }
}
const mapStateToProps = () => { return {} };

const mapDispatchToProps = {
  listCustomer: customerOperations.listCustomer,
  listLanguage: masterOperations.listLanguage
};

export default connect(mapStateToProps, mapDispatchToProps)(Customer);