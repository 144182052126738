import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'react-bootstrap';
import {
  Card, CardBody, CardHeader, CardFooter, Col, Nav, NavItem, NavLink, TabContent, TabPane,
  Form, Row, Input, Modal, ModalBody, ModalFooter, Label
} from 'reactstrap';
import { connect } from 'react-redux';
import * as XLSX from 'xlsx';
import { H5, H4, Btn } from '../../AbstractElements';
import excelImg from '../../assets/images/datatable/excel.png';
import userCircle from '../../assets/images/datatable/user-circle.png';
import list from '../../assets/images/datatable/list.png';
import SvgIcon from '../../Components/Common/Component/SvgIcon';
import { formatDateAndTime } from '../../utils/helper';
import Pagination from '../Pagination';
import '../../assets/scss/pagination.css';
import receipt from '../../assets/images/datatable/receipt.png';
import icon from '../../assets/images/datatable/icon.png';
import { masterOperations } from '../../state/ducks/master';
import { customerOperations } from '../../state/ducks/customer';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

class CustomerDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      BasicTab: '1',
      selectedData: {},
      purchaseItemsData: [],
      giftUserData:[],
      successItemsData: [],
      pendingItemsData: [],
      processingItemsData: [],
      shippedItemsData: [],
      canceledItemsData: [],
      currentPage: 1,
      itemsPerPage: 10,
      sortedColumn: null,
      sortOrder: 'asc',
      modalEmailOpen: false,
      modalNoteOpen: false
    };
  }

  async componentDidMount() {
    // get all customer items
    const customerId = localStorage.getItem("selectedCustomerId");
    const response = await this.props.listCustomerDetails({ "customerId": customerId });
    this.setState({ selectedData: response.payload });
    this.setState({ giftUserData: response.payload.gift_user });
    this.setState({ purchaseItemsData: response.payload.purchase_items });
    // for success customer items
    const successResponse = await this.props.listCustomerDetails({
      "customerId": customerId,
      "search": "{\"status\": \"completed\"}"
    }); 
    this.setState({ successItemsData: successResponse.payload.purchase_items });
    // for pending customer items
    const pendingResponse = await this.props.listCustomerDetails({
      "customerId": customerId,
      "search": "{\"status\": \"pending\"}"
    });
    this.setState({ pendingItemsData: pendingResponse.payload.purchase_items });
    // for processing customer items
    const processingResponse = await this.props.listCustomerDetails({
      "customerId": customerId,
      "search": "{\"status\": \"processing\"}"
    });
    this.setState({ processingItemsData: processingResponse.payload.purchase_items });
    // for shipped customer items
    const shippedResponse = await this.props.listCustomerDetails({
      "customerId": customerId,
      "search": "{\"status\": \"shipped\"}"
    });
    this.setState({ shippedItemsData: shippedResponse.payload.purchase_items });
    // for canceled customer items
    const canceledResponse = await this.props.listCustomerDetails({
      "customerId": customerId,
      "search": "{\"status\": \"cancelled\"}"
    });
    this.setState({ canceledItemsData: canceledResponse.payload.purchase_items });
  }

  sendEmail = async (e) => {
    e.preventDefault()
    try {
      const { selectedData } = this.state
      const response = await this.props.sendEmail({
        "to": selectedData.user_email,
        "subject": "Customer Details"
      });
      if (response.status === 200) {
        this.setState(prevState => ({
          modalEmailOpen: false
        }));
      }
      toast.success("Email send successfully.", { autoClose: 10000 });
    } catch (err) {
      toast.error("Something went wrong.", { autoClose: 10000 });
      console.log(err);
    }
  };

  updateNotes = async (e) => {
    e.preventDefault()
    try {
      const orderId = localStorage.getItem("selectedOrderId");
      console.log("orderId===>>>",orderId);
      const aid = parseFloat(orderId);
      const { selectedData } = this.state
      const response = await this.props.updateNote({
        userId: selectedData.user_id,
        note: selectedData.note,
        id:aid
      });
      if (response.status === 200) {
        this.setState(prevState => ({
          modalNoteOpen: false
        }));
      }
      toast.success("Notes updated successfully.", { autoClose: 10000 });
      window.open("/customers-management/customer-details/", "_self");
    } catch (err) {
      toast.error("Something went wrong.", { autoClose: 10000 });
      console.log(err);
    }
  };

  handleInput = async (e) => {
    try {
      let tid = e.target.id;
      const { selectedData } = this.state;
      switch (tid) {
        case 'note':
          const note = e.target.value;
          this.setState({
            selectedData: {
              ...selectedData,
              note
            }
          });
          break;
        default:
          console.log("No Matching target found in handleInput");
          break;
      }
    } catch (err) {
      console.log(err);
    }
  }

  generateExcelData = () => {
    const { purchaseItemsData } = this.state;
    const xlsxData = [
      ['Items', 'Reference No.', 'Amount', 'Status', 'Date Time', 'IP Address', 'Payment Method']
    ];
    for (const data of purchaseItemsData) {
      const dataRow = [
        data.order_item_name,
        data.referenceNumber,
        data.amount,
        data.status,
        formatDateAndTime(data.date),
        // data.device,
        data.ip_address,
        data.payment_method
      ];
      xlsxData.push(dataRow);
    }
    const worksheet = XLSX.utils.aoa_to_sheet(xlsxData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    return workbook;
  };

  handleDownload = () => {
    const workbook = this.generateExcelData();
    XLSX.writeFile(workbook, 'customer_details.xlsx');
  };

  handleShowDetails = () => {
    this.setState(prevState => ({
      modalEmailOpen: !prevState.modalEmailOpen
    }));
  };

  onCloseModal = () => {
    this.setState(prevState => ({
      modalEmailOpen: false
    }));
  }

  handleShowNotes = () => {
    this.setState(prevState => ({
      modalNoteOpen: !prevState.modalNoteOpen
    }));
  };

  onCloseNotesModal = () => {
    this.setState(prevState => ({
      modalNoteOpen: false
    }));
  }

  handleCustomerReceiptClick = (e) => {
    window.open("/customers-management/customer-details/customer-receipt/", "_self");
  }

  handlePageChange = (pageNumber) => {
    this.setState({ currentPage: pageNumber });
  };

  handleTableSort = (column) => {
    const { purchaseItemsData, sortedColumn, sortOrder } = this.state;
    // Toggle the sortOrder if the same column is clicked again
    const newSortOrder = column === sortedColumn && sortOrder === 'asc' ? 'desc' : 'asc';

    // Sort the data based on the selected column and sortOrder
    const sortedData = purchaseItemsData.slice().sort((a, b) => {
      if (newSortOrder === 'asc') {
        return a[column] > b[column] ? 1 : -1;
      } else {
        return a[column] < b[column] ? 1 : -1;
      }
    });
    this.setState({
      purchaseItemsData: sortedData,
      sortedColumn: column,
      sortOrder: newSortOrder,
    });
  };

  render() {
    const { BasicTab, modalEmailOpen, modalNoteOpen, selectedData, purchaseItemsData,giftUserData, successItemsData, pendingItemsData, processingItemsData, shippedItemsData, canceledItemsData,
      currentPage, itemsPerPage, sortedColumn, sortOrder } = this.state;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentData = purchaseItemsData.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(purchaseItemsData.length / itemsPerPage);
    return (
      <Fragment>
        <ToastContainer />
        <div className='page-title'>
          <Row>
            <Col xs='12'>
              <ol className='breadcrumb'>
                <li className='breadcrumb-item'>
                  <Link to="/dashboard/">
                    <SvgIcon iconId='stroke-home' />
                  </Link>
                </li>
                <li className='breadcrumb-item'>
                  <Link style={{ "color": "#000" }} to="/customers-management/">Customers</Link>
                </li>
                <li className='breadcrumb-item active'>Customer Details</li>
              </ol>
            </Col>
          </Row>
        </div>
        <Col sm='12' xl='12' className='xl-100'>
          <Card>
            <CardHeader className='d-flex align-items-center justify-content-between'>
              <H4>Customer Details</H4>
              <img src={excelImg} width="35" height="35" alt='excel' onClick={this.handleDownload}></img>
            </CardHeader>
            <CardBody>
              <Form>
                <Row>
                  <Col md='6' style={{ display: 'flex', alignItems: 'center' }}>
                    <img src={userCircle} width="22" height="22" alt='user' style={{ marginRight: '10px' }} />
                    <H5 style={{ margin: 0 }}>{selectedData.name}</H5>
                  </Col>
                  {/* <Col md='6' style={{ display: 'flex', alignItems: 'center' }}>
                    <img src={userCircle} width="22" height="22" alt='excel' style={{ marginRight: '10px' }} />
                    <H5 style={{ margin: 0 }}> Buyer Details</H5>
                  </Col> */}
                  {/* {giftUserData.length > 0 && (
                  <> */}
                  {/* <Col md='6' style={{ display: 'flex', alignItems: 'center' }}>
                    <img src={userCircle} width="22" height="22" alt='excel' style={{ marginRight: '10px' }} />
                    <H5 style={{ margin: 0 }}>Owner Details</H5>
                  </Col> */}
                  {/* </>
                    )} */}
                </Row>
                <Row>
                  <Col md='3' style={{ display: 'flex', flexDirection: 'column', color: '#595959', marginTop: 15 }}>
                    <div>
                      <div className='mb-2'>Name</div>
                      <div className='mb-2'>Email</div>
                      {/* <div className='mb-2'>Phone</div> */}
                      <div className='mb-2'>Address</div>
                      <div className='mb-2'>Total Payment</div>
                      <div className='mb-2'>Book User</div>
                      <div className='mb-2'>Language</div>
                      <div className='mb-4'>Notes</div>
                    </div>
                  </Col>
                  <Col md='3' style={{ display: 'flex', flexDirection: 'column', marginTop: 15 }}>
                    <div>
                      <div className='mb-2'>{selectedData.name}</div>
                      <div className='mb-2'>{selectedData.user_email}</div>
                      {/* <div className='mb-2'>{selectedData.phone_no}</div> */}
                      <div className='mb-2'>{selectedData.address}</div>
                      <div className='mb-2'>{selectedData.total_payment}</div>
                      <div className='mb-2'>{selectedData.book_user}</div>
                      <div className='mb-2'>{selectedData.language_id}</div>
                      <div className='mb-4'><Input type='textarea' placeholder='Notes' value={selectedData.note || ''} onClick={this.handleShowNotes} /></div>
                    </div>
                  </Col>
                  {/* {giftUserData.length > 0 && (
  <> */}
    {/* <Col md='3' style={{ display: 'flex', flexDirection: 'column', color: '#595959', marginTop: 15 }}>
      <div>
        <div className='mb-2'>Name</div>
        <div className='mb-2'>Email</div>
        <div className='mb-2'>Phone</div>
        <div className='mb-2'>Address</div>
        <div className='mb-2'>Total Payment</div>
        <div className='mb-2'>Book User</div>
        <div className='mb-2'>Language</div>
        <div className='mb-4'>Notes</div>
      </div>
    </Col>

    <Col md='3' style={{ display: 'flex', flexDirection: 'column', marginTop: 15 }}>
      {giftUserData.map((data, i) => (
        <div key={i}>
          <div className='mb-2'>{data.name}</div>
          <div className='mb-2'>{data.email}</div> 
          <div className='mb-2'>{selectedData.phone_no}</div> 
          <div className='mb-2'>{data.address}</div>
          <div className='mb-2'>{data.total_payment}</div>
          <div className='mb-2'>{data.book_user}</div>
          <div className='mb-2'>{data.language_id}</div>
          <div className='mb-4'><Input type='textarea' placeholder='Notes' value={selectedData.note || ''} onClick={this.handleShowNotes} /></div>
        </div>
      ))} 
    </Col> */}
  {/* </>
)} */}
                  {/* )} */}
                </Row>
                <Modal isOpen={modalNoteOpen} toggle={() => this.handleShowNotes()} style={{ "marginTop": "200px" }}>
                  <Form onSubmit={this.updateNotes}>
                    <span style={{ "textAlign": "right", "padding": "5px" }} onClick={this.onCloseNotesModal}>Close</span>
                    <ModalBody>
                      <div style={{ "marginTop": "-10px", "marginBottom": "20px", "padding": "7px", "background": "#890077", "color": "#fff", "height": "30px", "width": "100%", "borderRadius": "5px" }}>Update Notes</div>
                      <Row>
                        <Col md='2'></Col>
                        <Col md='8'>
                          <Label>Notes</Label>
                          <Input style={{ fontSize: '10px', padding: '11px' }} type="textarea" id='note' name='note' value={selectedData.note || ''} onChange={this.handleInput} />
                        </Col>
                      </Row>
                    </ModalBody>
                    <ModalFooter>
                      <Col md='4'></Col>&nbsp;&nbsp;&nbsp;&nbsp;
                      <Col>
                        <Btn attrBtn={{ color: "primary", type: "submit" }}>Update</Btn>&nbsp;&nbsp;
                      </Col>
                    </ModalFooter>
                  </Form>
                </Modal>
              </Form>
              <Row>
                <Col md='12' style={{ display: 'flex', alignItems: 'center' }}>
                  <img src={list} width="22" height="22" alt='excel' style={{ marginRight: '10px' }} />
                  <H5 style={{ margin: 0 }}>Purchase Items</H5>
                </Col>
              </Row>
              <Nav tabs>
                <NavItem>
                  <NavLink href='#javascript' className={BasicTab === '1' ? 'active' : ''} onClick={() => this.setState({ BasicTab: '1' })}>
                    All
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href='#javascript' className={BasicTab === '2' ? 'active' : ''} onClick={() => this.setState({ BasicTab: '2' })}>
                    Success
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href='#javascript' className={BasicTab === '3' ? 'active' : ''} onClick={() => this.setState({ BasicTab: '3' })}>
                    Pending
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href='#javascript' className={BasicTab === '4' ? 'active' : ''} onClick={() => this.setState({ BasicTab: '4' })}>
                    Processing
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href='#javascript' className={BasicTab === '5' ? 'active' : ''} onClick={() => this.setState({ BasicTab: '5' })}>
                    Shipping
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href='#javascript' className={BasicTab === '6' ? 'active' : ''} onClick={() => this.setState({ BasicTab: '6' })}>
                    Cancelled
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={BasicTab}>
                <TabPane className='fade show' tabId='1'>
                  <Table ref="tbl" striped hover responsive bordered id="data-table-zero">
                    <thead>
                      <tr>
                      <th onClick={() => this.handleTableSort('order_item_name')}>Items {sortedColumn === 'order_item_name' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                        <th onClick={() => this.handleTableSort('book_user_buyer_name')}>Buyer Name {sortedColumn === 'book_user_buyer_name' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                        <th onClick={() => this.handleTableSort('book_user_owner_name')}>Owner Name {sortedColumn === 'book_user_owner_name' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                        <th onClick={() => this.handleTableSort('referenceNumber')}>Reference No. {sortedColumn === 'referenceNumber' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                        <th onClick={() => this.handleTableSort('amount')}>Amount {sortedColumn === 'amount' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                        <th onClick={() => this.handleTableSort('status')}>Status {sortedColumn === 'status' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                        <th onClick={() => this.handleTableSort('date')}>Date Time {sortedColumn === 'date' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                        {/* <th onClick={() => this.handleTableSort('device')}>Device {sortedColumn === 'device' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th> */}
                        <th onClick={() => this.handleTableSort('ip_address')}>IP Address {sortedColumn === 'ip_address' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                        <th onClick={() => this.handleTableSort('payment_method')}>Payment Method {sortedColumn === 'payment_method' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                        <th>Receipt</th>
                        <th>Share</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentData.map((data, i) => (
                        data.order_items.map((item, j) => (
                          <tr key={`${i}-${j}`}>
                            <td>{item.order_item_name}</td>
                            <td>{data.book_user_buyer_name}</td>
                            <td>{data.book_user_owner_name}</td>
                            <td>{data.order_id}</td>
                            <td>{item.price}</td>
                            {/* {data.status === 'Pending' ? <td><span className='badge badge-light-info'>{data.status}</span></td> */}
                              {/* : */}
                               <td><span className='badge badge-light-primary'>{data.status}</span></td>
                            <td>{data.date}</td>
                            {/* <td>{data.device}</td> */}
                            <td>{data.ip_address}</td>
                            <td>{data.payment_method}</td>
                            <td><img src={receipt} alt='receipt' height={15} width={15} onClick={this.handleCustomerReceiptClick} /></td>
                            <td><img src={icon} alt='icon' height={15} width={15} onClick={this.handleShowDetails} /></td>
                          </tr>))
                      ))}
                    </tbody>
                  </Table>
                </TabPane>
                <TabPane tabId='2'>
                  <Table ref="tbl" striped hover responsive bordered id="data-table-zero">
                    <thead>
                      <tr>
                        <th onClick={() => this.handleTableSort('order_item_name')}>Items {sortedColumn === 'order_item_name' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                        <th onClick={() => this.handleTableSort('referenceNumber')}>Reference No. {sortedColumn === 'referenceNumber' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                        <th onClick={() => this.handleTableSort('amount')}>Amount {sortedColumn === 'amount' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                        <th onClick={() => this.handleTableSort('status')}>Status {sortedColumn === 'status' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                        <th onClick={() => this.handleTableSort('date')}>Date Time {sortedColumn === 'date' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                        {/* <th onClick={() => this.handleTableSort('device')}>Device {sortedColumn === 'device' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th> */}
                        <th onClick={() => this.handleTableSort('ip_address')}>IP Address {sortedColumn === 'ip_address' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                        <th onClick={() => this.handleTableSort('payment_method')}>Payment Method {sortedColumn === 'payment_method' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                        <th>Receipt</th>
                        <th>Share</th>
                      </tr>
                    </thead>
                    <tbody>
                      {successItemsData.map((data, i) => (
                        data.order_items.map((item, j) => (
                          <tr key={`${i}-${j}`}>
                            <td>{item.order_item_name}</td>
                            <td>{data.order_id}</td>
                            <td>{item.price}</td>
                            <td><span className='badge badge-light-primary' style={{ background: '#b3ffb3', color: '#00b300' }}>{data.status}</span></td>
                            <td>{data.date}</td>
                            {/* <td>{data.device}</td> */}
                            <td>{data.ip_address}</td>
                            <td>{data.payment_method}</td>
                            <td><img src={receipt} alt='receipt' height={15} width={15} onClick={this.handleCustomerReceiptClick} /></td>
                            <td><img src={icon} alt='icon' height={15} width={15} onClick={this.handleShowDetails} /></td>
                          </tr>
                        ))
                      ))}
                    </tbody>
                  </Table>
                </TabPane>
                <TabPane tabId='3'>
                  <Table ref="tbl" striped hover responsive bordered id="data-table-zero">
                    <thead>
                      <tr>
                        <th onClick={() => this.handleTableSort('order_item_name')}>Items {sortedColumn === 'order_item_name' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                        <th onClick={() => this.handleTableSort('referenceNumber')}>Reference No. {sortedColumn === 'referenceNumber' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                        <th onClick={() => this.handleTableSort('amount')}>Amount {sortedColumn === 'amount' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                        <th onClick={() => this.handleTableSort('status')}>Status {sortedColumn === 'status' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                        <th onClick={() => this.handleTableSort('date')}>Date Time {sortedColumn === 'date' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                        {/* <th onClick={() => this.handleTableSort('device')}>Device {sortedColumn === 'device' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th> */}
                        <th onClick={() => this.handleTableSort('ip_address')}>IP Address {sortedColumn === 'ip_address' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                        <th onClick={() => this.handleTableSort('payment_method')}>Payment Method {sortedColumn === 'payment_method' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                        <th>Receipt</th>
                        <th>Share</th>
                      </tr>
                    </thead>
                    <tbody>
                      {pendingItemsData.map((data, i) => (
                        data.order_items.map((item, j) => (
                          <tr key={`${i}-${j}`}>
                            <td>{item.order_item_name}</td>
                            <td>{data.order_id}</td>
                            <td>{item.price}</td>
                            <td><span className='badge badge-light-primary' style={{ background: '#ffffcc', color: '#808000' }}>{data.status}</span></td>
                            <td>{data.date}</td>
                            {/* <td>{data.device}</td> */}
                            <td>{data.ip_address}</td>
                            <td>{data.payment_method}</td>
                            <td><img src={receipt} alt='receipt' height={15} width={15} onClick={this.handleCustomerReceiptClick} /></td>
                            <td><img src={icon} alt='icon' height={15} width={15} onClick={this.handleShowDetails} /></td>
                          </tr>
                        ))
                      ))}
                    </tbody>
                  </Table>
                </TabPane>
                <TabPane tabId='4'>
                  <Table ref="tbl" striped hover responsive bordered id="data-table-zero">
                    <thead>
                      <tr>
                        <th onClick={() => this.handleTableSort('order_item_name')}>Items {sortedColumn === 'order_item_name' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                        <th onClick={() => this.handleTableSort('referenceNumber')}>Reference No. {sortedColumn === 'referenceNumber' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                        <th onClick={() => this.handleTableSort('amount')}>Amount {sortedColumn === 'amount' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                        <th onClick={() => this.handleTableSort('status')}>Status {sortedColumn === 'status' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                        <th onClick={() => this.handleTableSort('date')}>Date Time {sortedColumn === 'date' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                        {/* <th onClick={() => this.handleTableSort('device')}>Device {sortedColumn === 'device' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th> */}
                        <th onClick={() => this.handleTableSort('ip_address')}>IP Address {sortedColumn === 'ip_address' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                        <th onClick={() => this.handleTableSort('payment_method')}>Payment Method {sortedColumn === 'payment_method' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                        <th>Receipt</th>
                        <th>Share</th>
                      </tr>
                    </thead>
                    <tbody>
                      {processingItemsData.map((data, i) => (
                        data.order_items.map((item, j) => (
                          <tr key={`${i}-${j}`}>
                            <td>{item.order_item_name}</td>
                            <td>{data.order_id}</td>
                            <td>{item.price}</td>
                            <td><span className='badge badge-light-info' style={{ background: '#ffe6cc', color: '#ff9933' }}>{data.status}</span></td>
                            <td>{data.date}</td>
                            {/* <td>{data.device}</td> */}
                            <td>{data.ip_address}</td>
                            <td>{data.payment_method}</td>
                            <td><img src={receipt} alt='receipt' height={15} width={15} onClick={this.handleCustomerReceiptClick} /></td>
                            <td><img src={icon} alt='icon' height={15} width={15} onClick={this.handleShowDetails} /></td>
                          </tr>
                        ))
                      ))}
                    </tbody>
                  </Table>
                </TabPane>
                <TabPane tabId='5'>
                  <Table ref="tbl" striped hover responsive bordered id="data-table-zero">
                    <thead>
                      <tr>
                        <th onClick={() => this.handleTableSort('order_item_name')}>Items {sortedColumn === 'order_item_name' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                        <th onClick={() => this.handleTableSort('referenceNumber')}>Reference No. {sortedColumn === 'referenceNumber' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                        <th onClick={() => this.handleTableSort('amount')}>Amount {sortedColumn === 'amount' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                        <th onClick={() => this.handleTableSort('status')}>Status {sortedColumn === 'status' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                        <th onClick={() => this.handleTableSort('date')}>Date Time {sortedColumn === 'date' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                        {/* <th onClick={() => this.handleTableSort('device')}>Device {sortedColumn === 'device' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th> */}
                        <th onClick={() => this.handleTableSort('ip_address')}>IP Address {sortedColumn === 'ip_address' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                        <th onClick={() => this.handleTableSort('payment_method')}>Payment Method {sortedColumn === 'payment_method' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                        <th>Receipt</th>
                        <th>Share</th>
                      </tr>
                    </thead>
                    <tbody>
                      {shippedItemsData.map((data, i) => (
                        data.order_items.map((item, j) => (
                          <tr key={`${i}-${j}`}>
                            <td>{item.order_item_name}</td>
                            <td>{data.order_id}</td>
                            <td>{item.price}</td>
                            <td><span className='badge badge-light-info'>{data.status}</span></td>
                            <td>{data.date}</td>
                            {/* <td>{data.device}</td> */}
                            <td>{data.ip_address}</td>
                            <td>{data.payment_method}</td>
                            <td><img src={receipt} alt='receipt' height={15} width={15} onClick={this.handleCustomerReceiptClick} /></td>
                            <td><img src={icon} alt='icon' height={15} width={15} onClick={this.handleShowDetails} /></td>
                          </tr>
                        ))
                      ))}
                    </tbody>
                  </Table>
                </TabPane>
                <TabPane tabId='6'>
                  <Table ref="tbl" striped hover responsive bordered id="data-table-zero">
                    <thead>
                      <tr>
                        <th onClick={() => this.handleTableSort('order_item_name')}>Items {sortedColumn === 'order_item_name' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                        <th onClick={() => this.handleTableSort('referenceNumber')}>Reference No. {sortedColumn === 'referenceNumber' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                        <th onClick={() => this.handleTableSort('amount')}>Amount {sortedColumn === 'amount' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                        <th onClick={() => this.handleTableSort('status')}>Status {sortedColumn === 'status' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                        <th onClick={() => this.handleTableSort('date')}>Date Time {sortedColumn === 'date' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                        {/* <th onClick={() => this.handleTableSort('device')}>Device {sortedColumn === 'device' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th> */}
                        <th onClick={() => this.handleTableSort('ip_address')}>IP Address {sortedColumn === 'ip_address' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                        <th onClick={() => this.handleTableSort('payment_method')}>Payment Method {sortedColumn === 'payment_method' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                        <th>Receipt</th>
                        <th>Share</th>
                      </tr>
                    </thead>
                    <tbody>
                      {canceledItemsData.map((data, i) => (
                        data.order_items.map((item, j) => (
                          <tr key={`${i}-${j}`}>
                            <td>{item.order_item_name}</td>
                            <td>{data.order_id}</td>
                            <td>{item.price}</td>
                            <td><span className='badge badge-light-info' style={{ background: '#ffe6e6', color: '#ff0000' }}>{data.status}</span></td>
                            <td>{data.date}</td>
                            {/* <td>{data.device}</td> */}
                            <td>{data.ip_address}</td>
                            <td>{data.payment_method}</td>
                            <td><img src={receipt} alt='receipt' height={15} width={15} onClick={this.handleCustomerReceiptClick} /></td>
                            <td><img src={icon} alt='icon' height={15} width={15} onClick={this.handleShowDetails} /></td>
                          </tr>
                        ))
                      ))}
                    </tbody>
                  </Table>
                </TabPane>
              </TabContent>
              <Modal isOpen={modalEmailOpen} toggle={() => this.handleShowDetails()} style={{ "marginTop": "200px" }}>
                <Form onSubmit={this.sendEmail}>
                  <span style={{ "textAlign": "right", "padding": "5px" }} onClick={this.onCloseModal}>Close</span>
                  <ModalBody>
                    <div style={{ "marginTop": "-10px", "marginBottom": "20px", "padding": "7px", "background": "#890077", "color": "#fff", "height": "30px", "width": "100%", "borderRadius": "5px" }}>Send Email</div>
                    <Row>
                      <Col md='2'></Col>
                      <Col md='8'>
                        <Label>Email</Label>
                        <Input style={{ fontSize: '10px', padding: '11px' }} type="text" value={selectedData.user_email} disabled />
                      </Col>
                    </Row>
                  </ModalBody>
                  <ModalFooter>
                    <Col md='4'></Col>&nbsp;&nbsp;&nbsp;&nbsp;
                    <Col>
                      <Btn attrBtn={{ color: "primary", type: "submit" }}>Send</Btn>&nbsp;&nbsp;
                    </Col>
                  </ModalFooter>
                </Form>
              </Modal>
            </CardBody>
            <CardFooter>
              <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={this.handlePageChange} />
            </CardFooter>
          </Card>
        </Col>
      </Fragment>
    );
  }
}
const mapStateToProps = () => { return {} };

const mapDispatchToProps = {
  listCustomerDetails: customerOperations.listCustomerDetails,
  updateNote: customerOperations.updateNote,
  sendEmail: masterOperations.sendEmail
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerDetails);