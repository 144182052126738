import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Container, Card, CardBody, CardHeader, Col, Form, Row } from 'reactstrap';
import { H5 } from '../../AbstractElements';
import SvgIcon from '../../Components/Common/Component/SvgIcon';

class AdditionalConsiderations extends React.Component {

  onClickResetPassword = () => {
    window.open("/additional-considerations/reset-password/", "_self");
  }
  onClickAddLanguage = () => {
    window.open("/additional-considerations/add-language/", "_self");
  }
  render() {
    return (
      <Fragment>
        <div className='page-title'>
          <Row>
            <Col xs='12'>
              <ol className='breadcrumb'>
                <li className='breadcrumb-item'>
                  <Link to="/dashboard/">
                    <SvgIcon iconId='stroke-home' />
                  </Link>
                </li>
                <li className='breadcrumb-item active'>Additional Considerations</li>
              </ol>
            </Col>
          </Row>
        </div>
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              <Card>
                <CardHeader className='d-flex align-items-center justify-content-between'>
                  <H5>Additional Considerations</H5>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row>
                      <Col md='3' className='mt-3'>
                        <Card style={{ border: "1px solid #890077", background: '#ffe6fc' }} onClick={this.onClickResetPassword}>
                          <Row>
                            <div style={{ padding: "5%", textAlign: "center" }}>
                              <div style={{ color: '#890077', fontWeight: 'bold', padding: '5% 0% 5% 5%' }}>Reset Password</div>
                            </div>
                          </Row>
                        </Card>
                      </Col>
                      <Col md='3' className='mt-3'>
                        <Card style={{ border: "1px solid #890077", background: '#ffe6fc' }} onClick={this.onClickAddLanguage}>
                          <Row>
                            <div style={{ padding: "5%", textAlign: "center" }}>
                              <div style={{ color: '#890077', fontWeight: 'bold', padding: '5% 0% 5% 5%' }}>Add Language</div>
                            </div>
                          </Row>
                        </Card>
                      </Col>
                    </Row>
                    
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
  }
}

export default AdditionalConsiderations;