import * as types from "./types";
import * as API_URL from "../../../env";

export const listPrintBookOrder = () => ({
    type: types.FETCH_PRINT_ORDERBOOK_LIST,
    meta: {
        async: true,
        blocking: true,
        path: API_URL.BACKEND_SERVICE_API_URL+"/orderbook/get-additional-book-list",
        method: "GET",
        // body: payload
    },
});

export const listPrintBookOrderDetails = (payload) => ({
    type: types.FETCH_PRINT_ORDERBOOK_DETAILS,
    meta: {
        async: true,
        blocking: true,
        path: API_URL.BACKEND_SERVICE_API_URL+"/orderbook/get-additional-book-details",
        method: "POST",
        body: payload
    },
});